.navBar {
  padding: 0px 50px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  background: white;
}

.navBar img {
  max-width: 250px;
}

.navBarRight {
  margin: 25px 0;
}

.styleSpinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.navBarRight a {
  padding: 0;
  margin: auto 25px;
  color: var(--secondary) !important;
  font-weight: bold;
  text-decoration: none;
}

.navBarRight button:first-of-type {
  margin-right: 15px;
  margin-left: 25px;
}

.navDropdown > a {
  transform: translateY(50%);
}
.navDropdown > div a {
  margin: 0;
  padding: 10px 25px;
  display: block;
  position: relative;
  min-width: 200px;
  text-decoration: none;
}

.navDropdown > div a:hover {
  background: var(--primary);
  color: white !important;
}

.navDropdown > div {
  margin-left: 20px;
  /* padding: 0 20px; */
}

@media only screen and (max-width: 1200px) {
  .navBar {
    padding: 0.5rem 1rem;
  }
}
