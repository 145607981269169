.navBar {
    padding: 0px 50px;
    /* position: fixed; */
    width: 100%;
    top: 0;
    /* z-index: 0; */
    background: white;
  }
  
  .styleSpinner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  
  .navBar img {
    max-width: 250px;
  }
  
  .sideBarContainer{
    position: absolute;
    width: 100%;
    background: transparent;
    z-index: 3;
  }
  .sideBar{
    position: absolute;
    top:0;
    right:0;
    background: white;
    border-radius: 0 10px;
    border:1px solid #ccc;
    width: 200px;
    z-index: 10;
  }
  
  .dropDown a{
    text-align: center;
    margin:0  !important;
  }
  
  .dropDown a:hover{
  background: var(--primary);
  color:white !important;
  }
  
  .close{
    margin-right:-400px;
    
  }
  
  .navBarRight {
    margin: 10px 0;
    flex-direction: column;
  }
  
  .navBarRight a {
    margin: auto 25px;
    color: var(--secondary) !important;
    font-weight: bold;
    text-decoration: none;
    margin-bottom:20px;
    text-align: center;
  }
  
  .navBarRight button {
   margin:10px;
  
    /* margin-left: 25px; */
  }
  
  .settings{
    border:0;
    background: transparent;
  }
  
  .settings img{
    width: 25px;
  }
  .navDropdown > a {
    transform: translateY(25%);
  }
  .navDropdown > div a {
    margin: 0;
    padding: 10px 25px;
    display: block;
    position: relative;
    min-width: 200px;
    text-decoration: none;
  }
  
  .navDropdown > div a:hover {
    background: var(--primary);
    color: white !important;
  }
  
  .navDropdown > div {
    margin-left: 20px;
    /* padding: 0 20px; */
  }
  
  @media only screen and (max-width: 1200px) {
    .navBar {
      padding: 0.5rem 1rem;
    }
  }