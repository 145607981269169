.main {
  background-color: rgb(228, 244, 255);
}

.Intro img {
  width: 110%;
}

.Intro {
  padding-top: 160px;
  padding-bottom: 100px;
}

.HeightViewport {
  min-height: calc(100vh - 100px);
}

.Intro h1 {
  font-weight: bold;
  margin-top: 10px;
  font-size: 140px;
  color: rgb(0, 60, 131);
}

.Intro h2 {
  font-weight: bold;
  font-size: 38px;
  color: rgb(0, 60, 131);
}

.Intro p {
  color: rgb(0, 60, 131);
  font-size: 20px;
}

.Intro button,
.Outro button {
  color: white;
  background: rgb(0, 60, 131) !important;
  padding: 0 80px;
  width: auto;
  margin-top: 35px;
  outline: 0;
  border: 0;
}

.Intro button:hover,
.Outro button:hover,
.Intro button:active,
.Outro button:active {
  color: white;
  background: black !important;
  padding: 0 80px;
  width: auto;
  margin-top: 35px;
  box-shadow: none !important;
  border: none !important;
}

.Intro button:focus,
.Outro button:focus {
  background: rgb(0, 60, 131) !important;
  box-shadow: none !important;
  border: none !important;
}

@media only screen and (max-width: 768px) {
  .Intro {
    padding-top: 50px;
    padding-bottom: 80px;
  }
  .Intro img {
    width: 100%;
    margin-top: 100px;
  }

  .Intro h1 {
    font-weight: bold;
    margin-top: 10px;
    font-size: 140px;
    color: rgb(0, 60, 131);
  }

  .Intro h2 {
    font-weight: bold;
    font-size: 38px;
    color: rgb(0, 60, 131);
  }

  .Intro p {
    color: rgb(0, 60, 131);
    font-size: 20px;
  }
}

@media only screen and (max-width: 567px) {
}

@media only screen and (max-width: 1200px) {
  .Intro h1 {
    margin-top: 20%;
  }
}
