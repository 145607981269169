.navBar {
  padding: 0px 50px;
  /* position: fixed; */
  width: 100%;
  top: 0;
  /* z-index: 0; */
  background: white;
}

.styleSpinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.navBar img {
  max-width: 250px;
}

.sideBarContainer {
  position: absolute;
  width: 100%;
  background: transparent;
  z-index: 3;
}

.dropDown a {
  text-align: center;
  margin: 0 !important;
}

.dropDown a:hover {
  background: var(--primary);
  color: white !important;
}

.close {
  margin-right: -400px;
}

.navBarRight {
  margin: 10px 0;
  flex-direction: column;
}

.navBarRight a {
  margin: auto 25px;
  color: var(--secondary) !important;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 20px;
  text-align: center;
}

.navBarRight button {
  margin: 10px;

  /* margin-left: 25px; */
}

.settings {
  border: 0;
  background: transparent;
}

.settings img {
  width: 25px;
}
.navDropdown > a {
  transform: translateY(25%);
}
.navDropdown > div a {
  margin: 0;
  padding: 10px 25px;
  display: block;
  position: relative;
  min-width: 200px;
  text-decoration: none;
}

.navDropdown > div a:hover {
  background: var(--primary);
  color: white !important;
}

.navDropdown > div {
  margin-left: 20px;
  /* padding: 0 20px; */
}

@media only screen and (max-width: 1200px) {
  .navBar {
    padding: 0.5rem 1rem;
  }
}

.menuToggleButton {
  color: white;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  z-index: 3;
  font-size: 20px;
  border-right: 70px solid transparent;
  border-bottom: 70px solid rgba(0, 0, 0, 0.7);
}

.menuToggleButton p {
  position: absolute;
  margin-bottom: 0;
  bottom: -70px;
  left: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: bold;
  font-size: 30px;
}

.sideBar {
  height: 100%;
  /* width: 20%; */
  max-width: 16em;
  min-width: 14em;
  background: #37424a;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1;
  display: flex;
  flex-flow: column;
}

.logo {
  padding: 25px 15px;
}

.logo img {
  width: 100%;
}

.buttonContainer {
  height: 80px;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 50px;
  cursor: pointer;
  border-top: 2px solid #1e262d;
}

.documentation {
  border-bottom: 2px solid #1e262d;
}

.designer {
  border-top: 0px solid #1e262d;
  font-family: "DM Sans";
  font-weight: bold;
  margin-bottom: 0;
  color: #b8bfc4;
}

.buttonContainer p {
  flex: 1;
  margin-bottom: 0;
  /* font-size: 18px; */
  font-family: "DM Sans";
  font-weight: bold;
}

.designerContainer {
  background: #081621;
  flex: 1 1 auto;
  /* overflow-y: scroll; */
}

.thumbVertical {
  background-color: #37424a;
  border-radius: 5px;
}

.designerContainerEmpty {
  flex: 1 1 auto;
}

.buttonContainer:hover {
  background: #0090ff;
}

.designerContainer .title {
  text-transform: uppercase;
  font-family: "DM Sans";
  font-weight: bold;
  margin-bottom: 0;
  color: #b8bfc4;
  padding: 10px 65px;
}

.designerContainer .designerItem {
  background: #1e262c;
  padding: 10px 65px 10px 25%;
  color: white;
  /* font-size: 20px; */
  font-family: "DM Sans";
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  border-bottom: 2px solid #1e262d;
}

.designerItem.active {
  background: #0090ff;
}

.designerContainer .designerItem:hover {
  background: #0090ff;
}

.logout:hover {
  background: #ff5252;
}

.designer:hover {
  background: transparent;
  cursor: auto;
}

.open {
  left: 0;
}

@media only screen and (max-width: 768px) {
  .sideBar {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sideBar {
    width: 33%;
  }
  .logo img {
    width: 110%;
  }
}
