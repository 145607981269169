.Container{
    background: transparent;
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 11;
    cursor:no-drop;
}
.Modal{
    background: #fff;
    border: 1px solid #ccc;
    padding: 3%;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
}

.ButtonGroup{
    display: flex;
    justify-content: flex-end;
}

.ButtonGroup button{
    margin-left:10px;
}

.outPopUp {  
    width: 50px;
    height: 50px;
  }
  .ProgressContainer
  {
      width:200px;
      height:50px;
  }
  .ProgressContainer p{
      text-align: center;
  }