.notification {
  z-index: 10000;
  max-width: 600px;
  top: 25px;
  right: 25px;
  position: absolute;
}

.warningpadding td {
  padding-right: 10px;
}
