.root {
  display: "flex";
  margin-top: 30px;
}
.centered {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cardimgtop {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.goBack {
  color: #006ec3;
}

.goBack:hover {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.postImage {
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  margin-right: 40px;
  border-radius: 10px;
}

.postContainer {
  margin-bottom: 80px;
  padding: 20px 5px;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 0 7px 9px rgba(0, 0, 0, 0.04), 0 0 4px 5px rgba(0, 0, 0, 0.02),
    1px 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.blogText {
  max-height: 250px;
  overflow: hidden;
}

.Blog {
  padding-top: 50px;
  font-family: "DM Sans";
}

.tnc {
  font-family: "DM Sans";
}

.Blog img {
  max-width: 100%;
}

.Blog h1 {
  color: #006ec3;
  font-weight: bold;
  font-size: 24px;
}

.Blog h2 {
  color: #006ec3;
  font-weight: bold;
  font-size: 22px;
}

.Blog h3 {
  color: #006ec3;
  font-weight: bold;
  font-size: 20px;
}

.Blog h4 {
  color: #006ec3;
  font-weight: bold;
  font-size: 18px;
}

.Blog p {
  color: Black;
  font-size: 20px;
}

.Blog li {
  font-size: 20px;
}

.postDate {
  opacity: 0.6;
  font-size: 14px !important;
}
.subtitle {
  margin-top: 5px;
  font-size: 12px;
  color: #757575;
  margin-bottom: 40px;
}
.BlogTitle {
  font-size: 36px !important;
}

.aboutUs {
  margin-top: 35px;
  margin-bottom: 45px;
  font-size: 20px;
  color: #757575;
}

.teamMember {
  margin-top: 100px;
}

.name {
  color: var(--primary);
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
  font-family: "Encoded Sans";
}

.designation {
  color: #757575;
  font-weight: bold;
  font-size: 24px;
}

.about {
  font-size: 20px;
  color: #757575;
  margin: 25px 0;
}

.teamMember img {
  max-width: 100%;
  border-radius: 50%;
}

.Outro h1 {
  color: #535353;
  font-size: 30px;
  margin-top: 150px;
  font-weight: bold;
}

.Outro h3 {
  color: #535353;
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.centered h3 {
  color: #535353;
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.Outro {
  text-align: center;
}

.Outro button {
  margin: 30px 0 100px;
}

@media only screen and (max-width: 768px) {
  .Outro h1 {
    margin-top: 100px;
  }
  .postDate {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .teamMember > div:first-of-type {
    text-align: center;
  }
  .teamMember img {
    max-width: 60%;
  }
  .name {
    margin-top: 30px;
  }
}
