.PDDesigner {
  padding-top: 0px;
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: show;
}

.IframeWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.Iframe {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.outPopUp {
  /* position: absolute; */
  width: 50px;
  height: 50px;
  /* z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px; */
  /* background: red; */
}

.outPopUpcenter {
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.85); */
  padding: 300px 100px;
  position: absolute;
  z-index: 0;
}

.outPopUpTextColor {
  /* color: #fff; */
  text-align: center;
  /* font-size: 2em; */
}

.startDesigner {
  width: 100%;
  height: 100%;
  padding: 300px 100px;
}
