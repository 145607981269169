/* Overriding Bootstrap's Navbar Styles*/
.dropdown-menu {
  border: none;
}
.dropdown-toggle::after {
  margin-left: 10px;
  vertical-align: 3px;
}
@media only screen and (max-width: 1200px) {
  .collapse.show a,
  .collapsing a {
    padding: 0.5rem 1rem;
    margin: 0;
    transform: none;
    text-align: center;
  }
  .collapse.show button,
  .collapsing button {
    /* margin: 5px auto !important; */
    margin: 5px !important;
  }
  /* .navbar-toggler,
  .navbar-toggler collapsed {
    margin: 5px !important;
  } */

  .dropdown-menu a {
    color: #535353 !important;
  }
}
/* Overriding Bootstap's Button */
.btn {
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  min-width: 140px;
  min-height: 50px;
}

/* .active {
  border-bottom: 4px solid red;
} */

.btn-outline-secondary {
  border: 2px solid;
}

/*Custom Css */
/* @font-face {
  font-family: "Trade Gothic";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/TradeGothic/TradeGothicLTStd-Extended.otf");
}

@font-face {
  font-family: "Trade Gothic BLD";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/TradeGothic/TradeGothicLTStd-BoldExt.otf");
} */

@font-face {
  font-family: "Encoded Sans";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/EncodedSans/EncodeSansExpanded-Regular.ttf");
}

@font-face {
  font-family: "Encoded Sans BLD";
  font-style: bold;
  font-weight: 400;
  src: url("assets/fonts/EncodedSans/EncodeSansExpanded-Bold.ttf");
}

.white {
  color: white;
}

.icon {
  font-size: 35px;
  width: 45px;
}

.input-error {
  border: 3px solid red !important;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-left: 10px;
  margin-top: -20px;
  display: block;
  margin-bottom: 20px;
}

h1 {
  font-family: "Encoded Sans";
}

.subtitle {
  font-size: 30px !important;
  color: #535353 !important;
  margin: 30px 0px !important;
}

.section-heading {
  font-family: "Encoded Sans";
  font-weight: bold;
  font-size: 26px;
  color: #000;
  width: 100%;
  margin: 30px 0 25px 0;
}

.title {
  color: #006ec3;
  font-weight: bold;
  font-size: 36px;
  font-family: "Encoded Sans";
}

.paragraph {
  font-size: 20px;
  color: #000;
}

.paragraph-with-padding-top {
  font-size: 20px;
  color: #000;
  padding-top: 20px;
}

.text-center {
  text-align: center;
}
.margin-top-bottom-35 {
  margin: 30px 0;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-top-20 {
  margin-top: 20px;
}
/*Media Queries for larger screen sizes*/

@media (min-width: 1600px) {
  body {
    zoom: 1.1;
  }
}

@media (min-width: 1920px) {
  body {
    zoom: 1.25;
  }
}

@media (min-width: 2200px) {
  body {
    zoom: 1.5;
  }
}

@media (min-width: 2500px) {
  body {
    zoom: 1.7;
  }
}

@media (min-width: 2800px) {
  body {
    zoom: 2;
  }
}
