.notification{
    position: fixed;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.50);
    border-radius: 10px;
    width : 360px;
    top:100px;
    z-index:1;
    padding: 25px 50px;
    text-align: center;
    transition: all 0.5s;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.active{
    opacity: 1;
    right:10px;
    display: inherit;
}

.notification.hidden{
    opacity: 0;
    right:-10px;
    display: none;
}

.isError{
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}