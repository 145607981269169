.root {
  display: "flex";
  margin-top: 30px;
}

.cardimgtop {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.postImage {
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  margin-right: 40px;
  border-radius: 10px;
}

.postContainer {
  margin-bottom: 80px;
  padding-left: 40px;
  /* padding: 20px 5px;
  border-radius: 15px; */
  /* cursor: pointer; */
  /* box-shadow: 0 0 7px 9px rgba(0, 0, 0, 0.04), 0 0 4px 5px rgba(0, 0, 0, 0.02),
    1px 1px 1px 0 rgba(0, 0, 0, 0.04); */
}

.blogText {
  overflow: hidden;
}

.Documentation {
  padding-top: 50px;
  /* padding-top: 180px; */
  font-family: "DM Sans";
}

.Documentation img {
  max-width: 100%;
}

.Documentation h1 {
  color: #006ec3;
  font-weight: bold;
  font-size: 24px;
}

.Documentation h2 {
  color: #006ec3;
  font-weight: bold;
  font-size: 22px;
}

.Documentation h3 {
  color: #006ec3;
  font-weight: bold;
  font-size: 20px;
}

.Documentation h4 {
  color: #006ec3;
  font-weight: bold;
  font-size: 18px;
}

.Documentation p {
  color: Black;
  font-size: 20px;
}

.Documentation li {
  font-size: 20px;
}

.postDate {
  opacity: 0.6;
  font-size: 14px !important;
}
.subtitle {
  margin-top: 5px;
  font-size: 12px;
  color: #757575;
  margin-bottom: 40px;
}
.BlogTitle {
  font-size: 36px !important;
}

.aboutUs {
  margin-top: 35px;
  margin-bottom: 45px;
  font-size: 20px;
  color: #757575;
}

.teamMember {
  margin-top: 100px;
}

.name {
  color: var(--primary);
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
  font-family: "Encoded Sans";
}

.designation {
  color: #757575;
  font-weight: bold;
  font-size: 24px;
}

.about {
  font-size: 20px;
  color: #757575;
  margin: 25px 0;
}

.teamMember img {
  max-width: 100%;
  border-radius: 50%;
}

.Outro h1 {
  color: #535353;
  font-size: 30px;
  margin-top: 150px;
  font-weight: bold;
}

.Outro {
  text-align: center;
}

.Outro button {
  margin: 30px 0 100px;
}

.documentSidebar {
  position: sticky;
  top: 30%;
  left: 0;
  height: calc(100% + 80px);
  margin-bottom: 80px;
  border-left: 1px solid #ccc;
}
.documentSidebar p {
  margin: 20px 0px 5px;
}

.documentSidebar p:last-child {
  margin-bottom: 20px !important;
}
.documentSidebar span {
  padding: 10px;
  padding-left: 25px;
  display: block;
  font-size: 14px;
  cursor: pointer;
  margin-left: -17px;
}

.styleSpinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.documentSidebarActive {
  color: var(--secondary);
  font-weight: bold;
  border-left: 3px solid var(--primary);
}

@media only screen and (max-width: 1200px) {
  .documentSidebar {
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .Outro h1 {
    margin-top: 100px;
  }
  .postDate {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .teamMember > div:first-of-type {
    text-align: center;
  }
  .teamMember img {
    max-width: 60%;
  }
  .name {
    margin-top: 30px;
  }
  .documentSidebar {
    margin-left: 30px;
  }
}
