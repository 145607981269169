.Footer {
  padding: 80px;
  background: var(--secondary);
}

.Footer img {
  max-width: 300px;
  width: 100%;
}

.icon:first-of-type {
  margin-top: 35px;
}

.social_icon {
  margin-top: 20px;
  margin-left: 0px;
}

.icon p {
  display: inline-block;
  margin-left: 30px;
}

.icon span {
  vertical-align: middle;
}

.sections h1 {
  color: white;
  font-size: 18px;
  margin: 15px 0;
  font-weight: bold;
}

.sections p {
  color: white;
  margin: 5px 0;
}
.sections a,
.sections a:hover {
  color: white;
  text-decoration: none;
}

.copyRightSection {
  color: white;
  margin-top: 5px;
}

.copyRightSection p {
  position: absolute;
  bottom: 0;
  margin: 0;
}

.copyRightSection button {
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .Footer {
    padding: 30px;
  }
  .sections h1 {
    margin: 25px 0 15px 0;
  }

  .copyRightSection button {
    width: inherit;
    margin: 30px 0;
  }

  .copyRightSection p {
    position: relative;
  }
}

@media only screen and (max-width: 576px) {
  .icon p {
    margin-left: 5px;
  }
  .copyRightSection p {
    position: relative;
  }
}
