
body {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$theme-colors: (
  "primary": #0095FF,
  "secondary": #003C83
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
